import { useContext } from 'react';
import { GuessAnnotations } from "../../constants";
import TileDimensionsContext from '../../context/TileDimensionsContext';
import styles from './Annotation.module.css';

const FONT_SIZE_RATIO = 0.2;

function getAnnotationText(annotation) {
  switch (annotation) {
    case GuessAnnotations.TOO_LONG:
      return "Too Long!";
    case GuessAnnotations.TOO_SHORT:
      return "Too Short!";
    default:
      return "";
  }
}

function Annotation({ annotation }) {
  const {
    tileSize: size,
    tileMargin: margin,
  } = useContext(TileDimensionsContext);

  const annotationText = getAnnotationText(annotation);
  const sizeValue = `${size}px`;
  const marginValue = `${margin}px`;
  const fontSize = `${Math.floor(size * FONT_SIZE_RATIO)}px`;

  return (
    <div
      style={{
        width: sizeValue,
        height: sizeValue,
        margin: marginValue,
        fontSize,
      }}
      className={styles.annotation}
    >{annotationText}</div>
  );
}

export default Annotation;