import styles from './Loader.module.css';

const LOADER_RATIO = 0.5;

function Loader({ size }) {
  const loaderSize = `${Math.floor(size * LOADER_RATIO)}px`;
  return (
    <div
      style={{ width: loaderSize, height: loaderSize }}
      className={styles.loader}/>
  );
}

export default Loader;
