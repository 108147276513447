import { TileVerdicts } from "../../constants";

export const EXAMPLE = [
  { letter: 'E', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'X', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'A', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'M', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'P', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'L', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'E', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
];

export const BLINK = [
  { letter: 'B', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'L', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'I', verdict: TileVerdicts.LEFT, hasUncoveredAllInstances: true },
  { letter: 'N', verdict: TileVerdicts.RIGHT, hasUncoveredAllInstances: true },
  { letter: 'K', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
];

export const PERUSE = [
  { letter: 'P', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'E', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'R', verdict: TileVerdicts.LEFT, hasUncoveredAllInstances: false },
  { letter: 'U', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'S', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: false },
  { letter: 'E', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
];

export const NATION = [
  { letter: 'N', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'A', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'T', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'I', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'O', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'N', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
];

export const BRAINSTORM = [
  { letter: 'B', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'R', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'A', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'I', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'N', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'S', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'T', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'O', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'R', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'M', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
];
