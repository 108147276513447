import classnames from 'classnames';
import Button from "../Button";
import styles from './ActionButton.module.css';

function ActionButton({ children, className, onClick }) {
  return (
    <Button
      className={classnames(className, styles.actionButton)}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default ActionButton;
