import GameBoardRow from '../GameBoardRow';
import TileDimensionsContext from '../../context/TileDimensionsContext';
import { useTileDimensions } from '../../hooks/useTileDimensions';
import useWindowSize from '../../hooks/useWindowSize';

const MAX_CONTENT_WIDTH_PX = 500;

function ResponsiveGameBoardRow({ letterVerdicts, annotation }) {
  const { windowWidth } = useWindowSize();
  const numLetters = letterVerdicts.length + 2 + (annotation === undefined ? 0 : 1);
  const { tileSize, tileMargin } = useTileDimensions(Math.min(windowWidth, MAX_CONTENT_WIDTH_PX), numLetters);
  return (
    <TileDimensionsContext.Provider value={{ tileSize, tileMargin }}>
      <GameBoardRow letterVerdicts={letterVerdicts} annotation={annotation}/>
    </TileDimensionsContext.Provider>
  );
}

export default ResponsiveGameBoardRow;
