import { useRef } from 'react';
import { GameModes } from '../../constants';
import { useOnEscapePressIfOpen } from '../../hooks/useOnEscapePress';
// import useCurrentTimeInSeconds from '../../hooks/useCurrentTimeInSeconds';
import { getShareString, share } from '../../utils';
import ActionButton from '../ActionButton';
import CloseButton from '../CloseButton';
import { EnterExitTransition, TransitionProperties } from '../EnterExitTransition';
import withPortal from '../withPortal';
import styles from './ResultModal.module.css';

// function getTimeTillNextDaily(currentTimeInSeconds) {
//   const now = new Date(currentTimeInSeconds * 1000);
//   const midnightLocal = new Date(now);
//   midnightLocal.setHours(24, 0, 0, 0);
//   const secondsTillMidnightEdt = ((midnightLocal - now) / 1000) - ((now.getTimezoneOffset() - (4 * 60)) * 60);
//   const seconds = secondsTillMidnightEdt % 60;
//   const minutes = Math.floor(secondsTillMidnightEdt / 60) % 60;
//   const hours = Math.floor(secondsTillMidnightEdt / 3600) % 60;
//   return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
// }

function ResultModal({
  isOpen,
  gameMode,
  gameState,
  onRequestClose,
  onRequestNewGame,
  onShareCopied,
}) {
  const modalNode = useRef();
  useOnEscapePressIfOpen(isOpen, onRequestClose);
  // const currentTimeInSeconds = useCurrentTimeInSeconds();

  function handleOverlayClick(e) {
    if (modalNode.current !== e.target && !modalNode.current.contains(e.target))
    onRequestClose();
  }

  async function handleShareClick() {
    const result = await share(getShareString(gameState));
    if (result) {
      onShareCopied(result);
    }
  }

  return (
    <EnterExitTransition isOpen={isOpen} transitionProperties={TransitionProperties.OPACITY}>
      {({ transitionNodeRef: overlayTransitionNodeRef }) => (
        <EnterExitTransition isOpen={isOpen} transitionProperties={TransitionProperties.TRANSLATE}>
          {({ transitionNodeRef: modalTransitionNodeRef }) => (
            <div
              className={styles.overlay}
              ref={overlayTransitionNodeRef}
              onClick={handleOverlayClick}
            >
              <div className={styles.modalOuter} ref={modalNode}>
                <div className={styles.modalInner} ref={modalTransitionNodeRef}>
                  <div className={styles.header}>
                    You win!
                  </div>
                  <div className={styles.results}>
                    You got the solution, {gameState.solution}, in {gameState.guesses.length} guess{gameState.guesses.length === 1 ? '' : 'es'}!
                  </div>
                  {gameMode !== GameModes.DAILY ? null : (
                    <>
                      <div className={styles.buttonContainer}>
                        <ActionButton onClick={handleShareClick}>Share</ActionButton>
                      </div>
                      {/* <div className={styles.timer}>Next daily puzzle in: {getTimeTillNextDaily(currentTimeInSeconds)}</div> */}
                      <div className={styles.timer}>Next xrdle @ midnight EDT!</div>
                    </>
                  )}
                  {gameMode !== GameModes.UNLIMITED ? null : (
                    <div className={styles.buttonContainer}>
                      <ActionButton onClick={onRequestNewGame}>Play Again</ActionButton>
                    </div>
                  )}
                  <CloseButton className={styles.closeButton} onClick={onRequestClose}/>
                </div>
              </div>
            </div>
          )}
        </EnterExitTransition>
      )}
    </EnterExitTransition>
  );
}

export default withPortal(ResultModal);
