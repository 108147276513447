import { useMemo, useReducer } from 'react';
import { INITIAL_STATE } from './initialState';
import makeActions from './makeActions';
import reducer from './reducer';

function useApplicationState() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const actions = useMemo(() => makeActions(state, dispatch), [state, dispatch]);
  return [state, actions];
}

export default useApplicationState;