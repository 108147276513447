import classnames from 'classnames';
import { GameModes } from '../../constants';
import Button from '../Button';
import styles from './Header.module.css';

function Header({ gameMode, onGameModeChange, onHelpButtonClick }) {
  return (
    <div className={styles.headerOuter}>
      <div className={styles.headerInner}>
        <Button
          onClick={onHelpButtonClick}
          className={styles.helpButton}
        >
          <div className={styles.helpButtonInner}>?</div>
        </Button>
        <Button
          onClick={() => onGameModeChange(GameModes.DAILY)}
          className={classnames(styles.dailyButton, { [styles.selected]: gameMode === GameModes.DAILY })}>
            Daily
        </Button>
        <h1 className={styles.logo}>xrdle</h1>
        <Button
          onClick={() => onGameModeChange(GameModes.UNLIMITED)}
          className={classnames(styles.unlimitedButton, { [styles.selected]: gameMode === GameModes.UNLIMITED })}>
            Unlimited
        </Button>
        <div className={styles.rightSpacer}/>
      </div>
    </div>
  );
}

export default Header;