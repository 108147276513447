export const APPEND_GUESS_VERDICT = 'APPEND_GUESS_VERDICT';
export const CLOSE_INSTRUCTIONS = 'CLOSE_INSTRUCTIONS';
export const CLOSE_RESULTS_MODAL = 'CLOSE_RESULTS_MODAL';
export const CLOSE_SPLASH_SCREEN = 'CLOSE_SPLASH_SCREEN';
export const HIDE_TOAST = 'HIDE_TOAST';
export const INVALID_GUESS = 'INVALID_GUESS';
export const OPEN_INSTRUCTIONS = 'OPEN_INSTRUCTIONS';
export const SET_GAME_MODE = 'SET_GAME_MODE';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SOLUTION_LOAD = 'SOLUTION_LOAD';
export const SOLUTION_LOAD_FAILURE = 'SOLUTION_LOAD_FAILURE';
export const SOLUTION_LOAD_SUCCESS = 'SOLUTION_LOAD_SUCCESS';
export const VALIDATING_GUESS = 'VALIDATING_GUESS';
export const UPDATE_CURRENT_GUESS = 'UPDATE_CURRENT_GUESS';
