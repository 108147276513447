import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './EnterExitTransition.module.css';

export const TransitionProperties = {
  OPACITY: 1<<0,
  TRANSLATE: 1<<1,
};

function getClassNamesForTransitionProperties(transitionProperties) {
  if (transitionProperties === TransitionProperties.OPACITY) {
    return {
      appear: styles.appearOpacity,
      appearActive: styles.appearActiveOpacity,
      appearDone: styles.appearDoneOpacity,
      enter: styles.enterOpacity,
      enterActive: styles.enterActiveOpacity,
      enterDone: styles.enterDoneOpacity,
      exit: styles.exitOpacity,
      exitActive: styles.exitActiveOpacity,
      exitDone: styles.exitDoneOpacity,
    };
  }
  if (transitionProperties === TransitionProperties.TRANSLATE) {
    return {
      appear: styles.appearTranslate,
      appearActive: styles.appearActiveTranslate,
      appearDone: styles.appearDoneTranslate,
      enter: styles.enterTranslate,
      enterActive: styles.enterActiveTranslate,
      enterDone: styles.enterDoneTranslate,
      exit: styles.exitTranslate,
      exitActive: styles.exitActiveTranslate,
      exitDone: styles.exitDoneTranslate,
    };
  }
  if (transitionProperties === TransitionProperties.OPACITY | TransitionProperties.TRANSLATE) {
    return {
      appear: styles.appearOpacityTranslate,
      appearActive: styles.appearActiveOpacityTranslate,
      appearDone: styles.appearDoneOpacityTranslate,
      enter: styles.enterOpacityTranslate,
      enterActive: styles.enterActiveOpacityTranslate,
      enterDone: styles.enterDoneOpacityTranslate,
      exit: styles.exitOpacityTranslate,
      exitActive: styles.exitActiveOpacityTranslate,
      exitDone: styles.exitDoneOpacityTranslate,
    };
  }
  return {};
}

export function EnterExitTransition({
  children,
  isOpen,
  transitionProperties = TransitionProperties.OPACITY | TransitionProperties.TRANSLATE,
}) {
  const transitionNodeRef = useRef(null);
  const classNames = getClassNamesForTransitionProperties(transitionProperties);

  return (
    <CSSTransition
      appear={true}
      addEndListener={(_, done) => {
        transitionNodeRef.current.addEventListener('transitionend', done, false);
      }}
      classNames={classNames}
      in={isOpen}
      nodeRef={transitionNodeRef}
      mountOnEnter={true}
      timeout={300}
      unmountOnExit={true}
    >
      {children({ transitionNodeRef })}
    </CSSTransition>
  );
}
