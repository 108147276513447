import { useMemo } from 'react';

const MAX_TILE_SIZE_PX = 50;
const MAX_TILE_MARGIN_PX = 5;

function getMaxTiles(guesses, currentGuess) {
  let maxTiles = 0;
  for (const guess of guesses) {
    maxTiles = Math.max(maxTiles, guess.letterVerdicts.length);
  }
  maxTiles = Math.max(maxTiles, currentGuess.length);
  return maxTiles + 4; // next letter + loader + 1 tile of padding on either side
}

export function useTileDimensions(horizontalSpace, numTiles) {
  return useMemo(() => {
    const spacePerTile = Math.floor(horizontalSpace / numTiles);
    const calculatedTileMargin = Math.max(1, Math.floor(spacePerTile / 20));
    const calculatedTileSize = spacePerTile - (calculatedTileMargin * 2);

    return {
      tileSize: Math.min(MAX_TILE_SIZE_PX, calculatedTileSize),
      tileMargin: Math.min(MAX_TILE_MARGIN_PX, calculatedTileMargin),
    }
  }, [
    horizontalSpace,
    numTiles,
  ]);
}

export function useTileDimensionsForGameBoard(windowWidth, guesses, currentGuess) {
  const maxTiles = useMemo(() => getMaxTiles(guesses, currentGuess), [guesses, currentGuess]);
  return useTileDimensions(windowWidth, maxTiles);
}
