import { useCallback, useEffect } from 'react';

export function useOnEscapePress(onEscapePress) {
  useEffect(() => {
    function handleKeyDown({ key }) {
      if (key === 'Escape') {
        onEscapePress();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onEscapePress]);
}

export function useOnEscapePressIfOpen(isOpen, onEscapePress) {
  const handleEscapePress = useCallback(() => {
    if (isOpen) {
      onEscapePress();
    }
  }, [isOpen, onEscapePress]);
  useOnEscapePress(handleEscapePress);
} 

export default useOnEscapePress;
