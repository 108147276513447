import {
  ALPHABET,
  GameModes,
  HAS_DISMISSED_INSTRUCTIONS_KEY,
  KeyStates,
  Statuses,
} from '../constants';

const shouldTreatAsFirstVisit = (() => {
  const hasDismissedInstructions = localStorage.getItem(HAS_DISMISSED_INSTRUCTIONS_KEY);
  if (hasDismissedInstructions === null) return true;
  return !JSON.parse(hasDismissedInstructions);
})();

const DEFAULT_GAME_STATE = {
  currentGuess: '',
  guesses: [],
  status: Statuses.ACTIVE,
  isValidatingGuess: false,
  isCurrentGuessInvalid: false,
  keyStates: ALPHABET.reduce((collector, current) => {
    collector[current] = KeyStates.DEFAULT;
    return collector;
  }, {}),
  solution: '',
  solutionNumber: null,
  isSolutionLoading: true,
  isSolutionError: false, // TODO: handle this case in the UI
  dateCompleted: null, // either victory or defeat
};

const INITIAL_STATE = {
  gameMode: GameModes.DAILY, // TODO: pull from path?
  gameStates: Object.values(GameModes).reduce((collector, gameMode) => {
    collector[gameMode] = DEFAULT_GAME_STATE;
    return collector;
  }, {}),
  isResultModalOpen: false,
  isToastOpen: false,
  toastText: '',
  areInstructionsOpen: shouldTreatAsFirstVisit,
  isSplashScreenOpen: shouldTreatAsFirstVisit,
};

export {
  DEFAULT_GAME_STATE,
  INITIAL_STATE,
};