import { memo, useContext } from 'react';
import classnames from 'classnames';
// import { AnimateCssClassNames, TileVerdicts } from '../../constants';
import { TileVerdicts } from '../../constants';
import TileDimensionsContext from '../../context/TileDimensionsContext';
import styles from './Tile.module.css';

const LETTER_RATIO = 0.75;
const ARROW_RATIO = 0.22;
const ASTERISK_FONT_SIZE_RATIO = 0.7;
const ASTERISK_LINE_HEIGHT_RATIO = 0.5;

const verdictToStyleMap = {
  [TileVerdicts.DEFAULT]: styles.default,
  [TileVerdicts.CORRECT]: styles.correct,
  [TileVerdicts.INCORRECT]: styles.incorrect,
  [TileVerdicts.LEFT]: styles.left,
  [TileVerdicts.RIGHT]: styles.right,
  [TileVerdicts.INVALID]: styles.invalid,
};

function maybeRenderArrow(verdict, size) {
  if (verdict !== TileVerdicts.LEFT && verdict !== TileVerdicts.RIGHT) {
    return null;
  }

  let className;
  if (verdict === TileVerdicts.LEFT) {
    className = classnames(styles.arrow, styles.leftArrow);
  } else {
    className = classnames(styles.arrow, styles.rightArrow);
  }

  const arrowSize = Math.floor(size * ARROW_RATIO);
  return (
    <div
      style={{
        borderWidth: `0 ${arrowSize}px ${arrowSize}px 0`,
        padding: `${arrowSize}px`,
      }}
      className={className}/>
  );
}

function maybeRenderAsterisk(shouldRenderAsterisk, size) {
  if (shouldRenderAsterisk) {
    const fontSize = `${Math.ceil(size * ASTERISK_FONT_SIZE_RATIO)}px`;
    const lineHeight = `${Math.ceil(size * ASTERISK_LINE_HEIGHT_RATIO)}px`;
    return (
      <div
        style={{ fontSize, lineHeight, zIndex: 2 }}
        className={styles.asterisk}
      >*</div>
    );
  }
  return null
}

function Tile({
  letter = '',
  verdict = TileVerdicts.DEFAULT,
  shouldRenderAsterisk = false,
}) {
  const {
    tileSize: size,
    tileMargin: margin,
  } = useContext(TileDimensionsContext);

  return (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        margin: `${margin}px`,
      }}
      className={classnames(styles.tileOuter, verdictToStyleMap[verdict])}
    >
      {!letter ? null : (
        <div
        style={{
          fontSize: `${Math.ceil(size * LETTER_RATIO)}px`,
          lineHeight: `${size}px`,
        }}
        // className={classnames(styles.tileInner, AnimateCssClassNames.ANIMATED, AnimateCssClassNames.ZOOM_IN)}
        className={classnames(styles.tileInner)}
        // onAnimationEnd={e => e.stopPropagation()}
        >
          {maybeRenderArrow(verdict, size)}
          <span className={styles.letter}>{letter}</span>
          {maybeRenderAsterisk(shouldRenderAsterisk, size)}
        </div>
      )}
    </div>
  );
}

export default memo(Tile);