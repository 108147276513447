import { memo, useContext } from 'react';
import TileDimensionsContext from '../../context/TileDimensionsContext';
import Annotation from '../Annotation';
import Loader from '../Loader';
import Tile from '../Tile';
import styles from './GameBoardRow.module.css';

function GameBoardRow({ 
  annotation = null,
  letterVerdicts,
  rowIndex = 0,
  isLoaderVisible = false,
 }) {
  const { tileSize, tileMargin } = useContext(TileDimensionsContext);

  return (
    <div className={styles.row}>
      {letterVerdicts.map(({
        letter,
        verdict,
        hasUncoveredAllInstances,
      }, letterIndex) => (
        <Tile
          key={`guess-${rowIndex}-letter-${letterIndex}`}
          letter={letter}
          verdict={verdict}
          shouldRenderAsterisk={!hasUncoveredAllInstances}/>
      ))}
      {annotation === null ? null : (
        <Annotation annotation={annotation}/>
      )}
      {!isLoaderVisible ? null : (
        <div
          className={styles.loaderContainer}
          style={{
            height: `${tileSize}px`,
            width: `${tileSize}px`,
            right: `-${tileSize + tileMargin + /*tile border*/ 1}px`,
            top: `${tileMargin}px`,
          }}
        >
          <Loader size={tileSize}/>
        </div>
      )}
    </div>
  );
}

export default memo(GameBoardRow);
