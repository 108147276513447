import { useMemo } from 'react';
import { MAX_LENGTH, Statuses, TileVerdicts } from '../../constants';
import TileDimensionsContext from '../../context/TileDimensionsContext';
import useIsLoaderVisible from '../../hooks/useIsLoaderVisible';
import { useTileDimensionsForGameBoard } from '../../hooks/useTileDimensions';
import useWindowSize from '../../hooks/useWindowSize';
import GameBoardRow from '../GameBoardRow/GameBoardRow';
import styles from './GameBoard.module.css';

function getCurrentGuessLetterVerdicts(currentGuess, isCurrentGuessInvalid) {
  const letterVerdicts = currentGuess.split('').map((letter) => ({
    letter,
    verdict: isCurrentGuessInvalid ? TileVerdicts.INVALID : TileVerdicts.DEFAULT,
    hasUncoveredAllInstances: true,
  }));

  // Next letter
  if (currentGuess.length < MAX_LENGTH) {
    letterVerdicts.push({
      letter: '',
      verdict: TileVerdicts.DEFAULT,
      hasUncoveredAllInstances: true,
    });
  }

  return letterVerdicts;
}

function GameBoard({ gameState }) {
  const { currentGuess, guesses, status, isValidatingGuess, isCurrentGuessInvalid } = gameState;
  const currentGuessLetterVerdicts = useMemo(
    () => getCurrentGuessLetterVerdicts(currentGuess, isCurrentGuessInvalid),
    [currentGuess, isCurrentGuessInvalid]
  );
  
  const isLoaderVisible = useIsLoaderVisible(isValidatingGuess);
  const { windowWidth } = useWindowSize();
  const {
    tileSize,
    tileMargin,
  } = useTileDimensionsForGameBoard(windowWidth, guesses, currentGuess);

  return (
    <TileDimensionsContext.Provider value={{ tileSize, tileMargin }}>
      <div
        style={{
          paddingTop: `${tileSize}px`,
          paddingBottom: `${tileSize}px`,
        }}
        className={styles.gameBoard}
      >
        {guesses.map(({ letterVerdicts, annotation }, guessIndex) => (
          <GameBoardRow
            key={`guess-${guessIndex}`}
            annotation={annotation}
            letterVerdicts={letterVerdicts}
            rowIndex={guessIndex}
          />
        ))}
        {status !== Statuses.ACTIVE ? null : (
          <GameBoardRow
            key={`guess-${guesses.length}`}
            letterVerdicts={currentGuessLetterVerdicts}
            rowIndex={guesses.length}
            isLoaderVisible={isLoaderVisible}
          />
        )}
      </div>
    </TileDimensionsContext.Provider>
  );
}

export default GameBoard;