import { useEffect, useState } from 'react';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  });

  function handleResize() {
    setWindowSize({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;