import classnames from 'classnames';
import { EnterExitTransition } from '../EnterExitTransition';
import withPortal from '../withPortal';
import styles from './Toast.module.css';

function Toast({ isOpen, text }) {
  return (
    <EnterExitTransition isOpen={isOpen}>
      {({ transitionNodeRef }) => (
        <div className={styles.outer}>
          <div className={styles.inner} ref={transitionNodeRef}>
            <div className={classnames(styles.toast, { [styles.visible]: isOpen })}>{text}</div>
          </div>
        </div>
      )}
    </EnterExitTransition>
  );
}

export default withPortal(Toast);
