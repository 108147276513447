import { useEffect, useRef, useState } from 'react';

const LOADER_TIMEOUT_DURATION = 350;

function useIsLoaderVisible(isLoading) {
  const [isLoaderVisible, setIsLoaderVisible] = useState(isLoading);
  const loaderTimeout = useRef(null);

  useEffect(() => {
    if (!isLoading) return;

    loaderTimeout.current = setTimeout(
      () => setIsLoaderVisible(true),
      LOADER_TIMEOUT_DURATION
    );

    return () => {
      clearTimeout(loaderTimeout.current);
      loaderTimeout.current = null;
      setIsLoaderVisible(false);
    };
  }, [isLoading]);

  return isLoaderVisible;
}

export default useIsLoaderVisible;