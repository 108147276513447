import { GuessAnnotations } from '../../constants';
import { useOnEscapePressIfOpen } from '../../hooks/useOnEscapePress';
import ActionButton from '../ActionButton';
import CloseButton from '../CloseButton';
import { EnterExitTransition } from '../EnterExitTransition';
import ResponsiveGameBoardRow from '../ResponsiveGameBoardRow';
import withPortal from '../withPortal';
import { BLINK, EXAMPLE, PERUSE, NATION, BRAINSTORM } from './examples';
import styles from './Instructions.module.css';

function Instructions({
  isOpen,
  onRequestClose,
}) {
  useOnEscapePressIfOpen(isOpen, onRequestClose);

  return (
   <EnterExitTransition isOpen={isOpen}>
     {({ transitionNodeRef }) => (
       <div className={styles.instructionsOuter}>
        <div ref={transitionNodeRef} className={styles.instructionsInner}>
          <div className={styles.content}>
            <CloseButton className={styles.closeButton} onClick={onRequestClose}/>
            <h2>How to Play</h2>
            <p>Guess the <b>xrdle</b> as quickly as possible!</p>
            <p>The solution could be any number of letters, and each guess must be a valid word. Hit the enter button to submit.</p>
            <p>After each guess, the tiles will change to show how close your guess was to the solution.</p>
            <hr/>
            <h3>Examples</h3>
            <ResponsiveGameBoardRow letterVerdicts={EXAMPLE}/>
            <p>The letter <b>X</b> is in the solution and in the correct position.</p>
            <ResponsiveGameBoardRow letterVerdicts={BLINK}/>
            <p>The letter <b>I</b> is in the solution but needs to move to the left by one or more positions. The letter <b>N</b> is in the solution but needs to move to the right by one or more positions.</p>
            <ResponsiveGameBoardRow letterVerdicts={PERUSE}/>
            <p>The letter <b>R</b> is in the solution but needs to move to the left by one or more positions, and there is at least one more instance of the letter <b>R</b> in the solution. The letter <b>S</b> is in the solution and in the correct position, and there is at least one more instance of the letter <b>S</b> in the solution.</p>
            <ResponsiveGameBoardRow letterVerdicts={NATION} annotation={GuessAnnotations.TOO_SHORT}/>
            <p>All of the letters in the guess are in the solution and in the correct positions, but the solution is longer than the guess. In this example, <b>NATIONAL</b> would be a reasonable next guess.</p>
            <ResponsiveGameBoardRow letterVerdicts={BRAINSTORM} annotation={GuessAnnotations.TOO_LONG}/>
            <p>The solution is shorter than the guess. Try removing the incorrect letters at the end. In this example, <b>BRAIN</b> would be a reasonable next guess.</p>
            <hr/>
            <p>When playing in <b>DAILY</b> mode, a new <b>xrdle</b> will be available each day!</p>
            <ActionButton className={styles.closeActionButton} onClick={onRequestClose}>Close</ActionButton>
          </div>
        </div>
      </div>
     )}
   </EnterExitTransition>
  );
}

export default withPortal(Instructions);
