import memoizeOne from 'memoize-one';
import { ALPHABET, GameModes, HapticFeedbackEvents, HAS_DISMISSED_INSTRUCTIONS_KEY, MAX_LENGTH, Statuses } from '../constants';
import {
  getDailyWord,
  getUnlimitedWord,
  isValidWord,
  loadDailyGameState,
  maybeProvideHapticFeedback,
  produceVerdictAndKeyStates,
} from '../utils';
import {
  APPEND_GUESS_VERDICT,
  CLOSE_INSTRUCTIONS,
  CLOSE_RESULTS_MODAL,
  CLOSE_SPLASH_SCREEN,
  HIDE_TOAST,
  INVALID_GUESS,
  OPEN_INSTRUCTIONS,
  SET_GAME_MODE,
  SHOW_TOAST,
  SOLUTION_LOAD,
  SOLUTION_LOAD_FAILURE,
  SOLUTION_LOAD_SUCCESS,
  UPDATE_CURRENT_GUESS,
  VALIDATING_GUESS,
} from './actions';
import { DEFAULT_GAME_STATE } from './initialState';

function loadUnlimitedSolution(dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      const solution = await getUnlimitedWord();
      const gameState = {
        ...DEFAULT_GAME_STATE,
        solution,
        isSolutionLoading: false,
        isSolutionError: false,
      };
      dispatch({ type: SOLUTION_LOAD_SUCCESS, payload: { gameMode: GameModes.UNLIMITED, gameState } });
      resolve();
    } catch (error) {
      dispatch({ type: SOLUTION_LOAD_FAILURE, payload: { gameMode: GameModes.UNLIMITED, error } });
      reject(error);
    }
  });
}

const closeInstructions = memoizeOne((dispatch) => {
  return () => {
    localStorage.setItem(HAS_DISMISSED_INSTRUCTIONS_KEY, JSON.stringify(true));
    dispatch({ type: CLOSE_INSTRUCTIONS });
  };
});

const openInstructions = memoizeOne((dispatch) => {
  return () => dispatch({ type: OPEN_INSTRUCTIONS });
});

const closeResultsModal = memoizeOne((dispatch) => {
  return () => dispatch({ type: CLOSE_RESULTS_MODAL });
});

const closeSplashScreen = memoizeOne((dispatch) => {
  return () => dispatch({ type: CLOSE_SPLASH_SCREEN });
});

const initialize = memoizeOne((dispatch) => {
  return () => Promise.allSettled([
    new Promise(async (resolve, reject) => {
      try {
        const {
          word: solution,
          number: solutionNumber,
        } = await getDailyWord();
        const gameState = {
          ...(loadDailyGameState(solution) ?? DEFAULT_GAME_STATE),
          solution,
          solutionNumber,
          isSolutionLoading: false,
          isSolutionError: false,
        };
        dispatch({ type: SOLUTION_LOAD_SUCCESS, payload: { gameMode: GameModes.DAILY, gameState } });
        resolve();
      } catch (error) {
        dispatch({ type: SOLUTION_LOAD_FAILURE, payload: { gameMode: GameModes.DAILY, error } });
        reject(error);
      }
    }),
    loadUnlimitedSolution(dispatch),
  ]);
});

async function keyDown(state, dispatch, key) {
  const {
    gameMode,
    gameStates,
  } = state;
  const gameState = gameStates[gameMode];
  const {
    isValidatingGuess,
    isSolutionLoading,
    isSolutionError,
    status,
    currentGuess,
    isCurrentGuessInvalid,
    solution,
    keyStates,
  } = gameState;

  if (
    isSolutionLoading ||
    isValidatingGuess ||
    isSolutionError ||
    status !== Statuses.ACTIVE
  ) {
    return;
  }

  switch (key) {
    case 'Enter':
      if (currentGuess === '' || isCurrentGuessInvalid) {
        return;
      }

      const didWin = currentGuess === solution;

      if (!didWin) {
        dispatch({ type: VALIDATING_GUESS });
        const isValid = await isValidWord(currentGuess); // TODO: try catch this and handle error case
        if (!isValid) {
          maybeProvideHapticFeedback(HapticFeedbackEvents.INVALID_GUESS);
          return dispatch({ type: INVALID_GUESS });
        }
      } else {
        maybeProvideHapticFeedback(HapticFeedbackEvents.VICTORY);
      }

      const {
        verdict,
        keyStates: newKeyStates,
      } = produceVerdictAndKeyStates(solution, currentGuess, keyStates);
      return dispatch({ type: APPEND_GUESS_VERDICT, payload: {
        verdict,
        keyStates: newKeyStates,
        ...(!didWin ? {} : {
          status: Statuses.VICTORY,
          dateCompleted: Date.now(),
        }),
      } });
    case 'Backspace':
      if (currentGuess.length > 0) {
        dispatch({ type: UPDATE_CURRENT_GUESS, payload: currentGuess.substring(0, currentGuess.length - 1) });
      }
      break;
    default: {
      key = key.toUpperCase();
      if (
        ALPHABET.includes(key) &&
        currentGuess.length < MAX_LENGTH
      ) {
        dispatch({ type: UPDATE_CURRENT_GUESS, payload: currentGuess + key });
      }
      break;
    }
  }
}

function newGame(state, dispatch) {
  if (state.gameMode === GameModes.UNLIMITED) {
    dispatch({ type: SOLUTION_LOAD, payload: { gameMode: GameModes.UNLIMITED } });
    loadUnlimitedSolution(dispatch);
  }
}

function setGameMode(_, dispatch, gameMode) {
  dispatch({ type: SET_GAME_MODE, payload: gameMode });
}

function showToast(_, dispatch, text) {
  dispatch({ type: SHOW_TOAST, payload: { text} });
}

const hideToast = memoizeOne((dispatch) => {
  return () => dispatch({ type: HIDE_TOAST });
});

function makeActions(state, dispatch) {
  return {
    closeInstructions: closeInstructions(dispatch),
    closeResultsModal: closeResultsModal(dispatch),
    closeSplashScreen: closeSplashScreen(dispatch),
    hideToast: hideToast(dispatch),
    initialize: initialize(dispatch),
    keyDown: (key) => keyDown(state, dispatch, key),
    newGame: () => newGame(state, dispatch),
    openInstructions: openInstructions(dispatch),
    setGameMode: (gameMode) => setGameMode(state, dispatch, gameMode),
    showToast: (text) => showToast(state, dispatch, text),
  };
}

export default makeActions;