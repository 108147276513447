import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { AnimateCssClassNames, TileVerdicts } from '../../constants';
import ResponsiveGameBoardRow from '../ResponsiveGameBoardRow';
import withPortal from '../withPortal';
import styles from './SplashScreen.module.css';

const AnimationStates = {
  PRE: 0,
  ENTER_WELCOME: 1,
  ENTER_LOGO: 2,
  FLOURISH_LOGO: 3,
  EXIT_ALL: 4,
  DONE: 5,
};

export const XRDLE = [
  { letter: 'X', verdict: TileVerdicts.CORRECT, hasUncoveredAllInstances: true },
  { letter: 'R', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'D', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'L', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
  { letter: 'E', verdict: TileVerdicts.INCORRECT, hasUncoveredAllInstances: true },
];

function getSplashScreenClassNames(animationState) {
  const classNames = [styles.splashScreen];

  switch (animationState) {
    case AnimationStates.EXIT_ALL:
      classNames.push(AnimateCssClassNames.ANIMATED, AnimateCssClassNames.FADE_OUT);
      break;
    case AnimationStates.DONE:
      classNames.push(styles.displayNone);
      break;
    default:
      break;
  }

  return classnames(classNames);
}

function getWelcomeClassNames(animationState) {
  const classNames = [styles.welcome];

  switch (animationState) {
    case AnimationStates.PRE:
      classNames.push(styles.invisible);
      break;
    case AnimationStates.ENTER_WELCOME:
      classNames.push(AnimateCssClassNames.ANIMATED, AnimateCssClassNames.FADE_IN);
      break;
    case AnimationStates.EXIT_ALL:
      classNames.push(AnimateCssClassNames.ANIMATED, AnimateCssClassNames.BACK_OUT_LEFT);
      break;
    case AnimationStates.DONE:
      classNames.push(styles.displayNone);
      break;  
    default:
      break;
  }

  return classnames(classNames);
}

function getLogoClassNames(animationState) {
  const classNames = [styles.logo];

  switch (animationState) {
    case AnimationStates.PRE:
    case AnimationStates.ENTER_WELCOME:
      classNames.push(styles.invisible);
      break;
    case AnimationStates.ENTER_LOGO:
      classNames.push(AnimateCssClassNames.ANIMATED, AnimateCssClassNames.BOUNCE_IN_DOWN);
      break;
    case AnimationStates.FLOURISH_LOGO:
      classNames.push(AnimateCssClassNames.ANIMATED, AnimateCssClassNames.RUBBER_BAND);
      break;
    case AnimationStates.EXIT_ALL:
      classNames.push(AnimateCssClassNames.ANIMATED, AnimateCssClassNames.BACK_OUT_RIGHT);
      break;
    case AnimationStates.DONE:
      classNames.push(styles.displayNone);
      break;
    default:
      break;
  }

  return classnames(classNames);
}

function SplashScreen({ onFinish = () => {} }) {
  const [animationState, setAnimationState] = useState(AnimationStates.PRE);

  function handleAnimationEnd(e) {
    e.stopPropagation();
    switch (animationState) {
      case AnimationStates.ENTER_WELCOME:
        setAnimationState(AnimationStates.ENTER_LOGO);
        break;
      case AnimationStates.ENTER_LOGO:
        setAnimationState(AnimationStates.FLOURISH_LOGO);
        break;
      case AnimationStates.FLOURISH_LOGO:
        setTimeout(() => setAnimationState(AnimationStates.EXIT_ALL), 1200);
        break;
      case AnimationStates.EXIT_ALL:
        setAnimationState(AnimationStates.DONE);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setAnimationState(AnimationStates.ENTER_WELCOME);
  }, []);

  useEffect(() => {
    if (animationState === AnimationStates.DONE) {
      onFinish();
    }
  }, [onFinish, animationState]);

  return (
    <div className={getSplashScreenClassNames(animationState)}>
      <div className={styles.splashScreenContent} onAnimationEnd={handleAnimationEnd}>
        <div className={getWelcomeClassNames(animationState)}>
          Welcome To
        </div>
        <div className={getLogoClassNames(animationState)}>
          <ResponsiveGameBoardRow letterVerdicts={XRDLE}/>
        </div>
      </div>
    </div>
  );
}

export default withPortal(SplashScreen);
