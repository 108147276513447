import { Statuses } from '../constants';
import {
  APPEND_GUESS_VERDICT,
  CLOSE_INSTRUCTIONS,
  CLOSE_RESULTS_MODAL,
  CLOSE_SPLASH_SCREEN,
  HIDE_TOAST,
  INVALID_GUESS,
  OPEN_INSTRUCTIONS,
  SET_GAME_MODE,
  SHOW_TOAST,
  SOLUTION_LOAD,
  SOLUTION_LOAD_FAILURE,
  SOLUTION_LOAD_SUCCESS,
  UPDATE_CURRENT_GUESS,
  VALIDATING_GUESS,
} from './actions';

function reducer(state, action) {
  switch (action.type) {
    case APPEND_GUESS_VERDICT:
      return {
        ...state,
        gameStates: {
          ...state.gameStates,
          [state.gameMode]: {
            ...state.gameStates[state.gameMode],
            guesses: [...state.gameStates[state.gameMode].guesses, action.payload.verdict],
            keyStates: action.payload.keyStates,
            currentGuess: '',
            isValidatingGuess: false,
            status: action.payload.status ?? state.gameStates[state.gameMode].status,
            dateCompleted: action.payload.dateCompleted ?? state.gameStates[state.gameMode].dateCompleted,
          },
        },
        isResultModalOpen: action.payload.status !== undefined && action.payload.status !== Statuses.ACTIVE,
      };
    case CLOSE_INSTRUCTIONS:
      return {
        ...state,
        areInstructionsOpen: false,
      };
    case CLOSE_RESULTS_MODAL:
      return {
        ...state,
        isResultModalOpen: false,
      };
    case CLOSE_SPLASH_SCREEN:
      return {
        ...state,
        isSplashScreenOpen: false,
      };
    case HIDE_TOAST:
      return {
        ...state,
        isToastOpen: false,
      };
    case INVALID_GUESS:
      return {
        ...state,
        gameStates: {
          ...state.gameStates,
          [state.gameMode]: {
            ...state.gameStates[state.gameMode],
            isValidatingGuess: false,
            isCurrentGuessInvalid: true,
          },
        },
      };
    case OPEN_INSTRUCTIONS:
      return {
        ...state,
        areInstructionsOpen: true,
      };
    case SET_GAME_MODE:
      return {
        ...state,
        gameMode: action.payload,
        isResultModalOpen: state.gameStates[action.payload].status !== Statuses.ACTIVE,
      };
    case SHOW_TOAST:
      return {
        ...state,
        isToastOpen: true,
        toastText: action.payload.text,
      };
    case SOLUTION_LOAD:
      return {
        ...state,
        gameStates: {
          ...state.gameStates,
          [action.payload.gameMode]: {
            ...state.gameStates[action.payload.gameMode],
            isSolutionLoading: true,
            isSolutionError: false,
          },
        },
        ...(action.payload.gameMode !== state.gameMode ? {} : {
          isResultModalOpen: false,
        }),
      };
    case SOLUTION_LOAD_FAILURE:
      return {
        ...state,
        gameStates: {
          ...state.gameStates,
          [action.payload.gameMode]: {
            ...state.gameStates[action.payload.gameMode],
            isSolutionLoading: false,
            isSolutionError: true,
          },
        },
      };
    case SOLUTION_LOAD_SUCCESS:
      return {
        ...state,
        gameStates: {
          ...state.gameStates,
          [action.payload.gameMode]: action.payload.gameState,
        },
        ...(action.payload.gameMode !== state.gameMode ? {} : {
          isResultModalOpen: action.payload.gameState.status !== Statuses.ACTIVE
        }),
      };
    case UPDATE_CURRENT_GUESS:
      return {
        ...state,
        gameStates: {
          ...state.gameStates,
          [state.gameMode]: {
            ...state.gameStates[state.gameMode],
            currentGuess: action.payload,
            isCurrentGuessInvalid: false,
          },
        },
      };
    case VALIDATING_GUESS:
      return {
        ...state,
        gameStates: {
          ...state.gameStates,
          [state.gameMode]: {
            ...state.gameStates[state.gameMode],
            isValidatingGuess: true,
          },
        },
      };
    default:
      throw new Error('bad action type');
  }
}

export default reducer;
