import { memo } from 'react';
import classnames from 'classnames';
import { KeyStates } from '../../constants';
import Button from '../Button';
import { ReactComponent as BackspaceIcon } from './backspace.svg';
import { ReactComponent as CheckIcon } from './check.svg';
import styles from './Keyboard.module.css';

const KEYS = [
  { key: 'A', gridArea: 'a', label: 'A' },
  { key: 'B', gridArea: 'b', label: 'B' },
  { key: 'C', gridArea: 'c', label: 'C' },
  { key: 'D', gridArea: 'd', label: 'D' },
  { key: 'E', gridArea: 'e', label: 'E' },
  { key: 'F', gridArea: 'f', label: 'F' },
  { key: 'G', gridArea: 'g', label: 'G' },
  { key: 'H', gridArea: 'h', label: 'H' },
  { key: 'I', gridArea: 'i', label: 'I' },
  { key: 'J', gridArea: 'j', label: 'J' },
  { key: 'K', gridArea: 'k', label: 'K' },
  { key: 'L', gridArea: 'l', label: 'L' },
  { key: 'M', gridArea: 'm', label: 'M' },
  { key: 'N', gridArea: 'n', label: 'N' },
  { key: 'O', gridArea: 'o', label: 'O' },
  { key: 'P', gridArea: 'p', label: 'P' },
  { key: 'Q', gridArea: 'q', label: 'Q' },
  { key: 'R', gridArea: 'r', label: 'R' },
  { key: 'S', gridArea: 's', label: 'S' },
  { key: 'T', gridArea: 't', label: 'T' },
  { key: 'U', gridArea: 'u', label: 'U' },
  { key: 'V', gridArea: 'v', label: 'V' },
  { key: 'W', gridArea: 'w', label: 'W' },
  { key: 'X', gridArea: 'x', label: 'X' },
  { key: 'Y', gridArea: 'y', label: 'Y' },
  { key: 'Z', gridArea: 'z', label: 'Z' },
  { key: 'Enter', gridArea: 'E', label: <CheckIcon className={styles.checkIcon}/> },
  { key: 'Backspace', gridArea: 'B', label: <BackspaceIcon className={styles.backspaceIcon}/> },
];

const keyStatesToStyleMap = {
  [KeyStates.DEFAULT]: styles.default,
  [KeyStates.SHIFT_MORE]: styles.shiftMore,
  [KeyStates.SHIFT]: styles.shift,
  [KeyStates.CORRECT_SHIFT_MORE]: styles.correctShiftMore,
  [KeyStates.CORRECT_MORE]: styles.correctMore,
  [KeyStates.CORRECT_SHIFT]: styles.correctShift,
  [KeyStates.CORRECT]: styles.correct,
  [KeyStates.INCORRECT]: styles.incorrect,
};

function Keyboard({ onKeyDown, keyStates }) {
  return (
    <div className={styles.keyboard}>
      {KEYS.map(({ key, gridArea, label }) => (
        <Button
          key={key}
          onClick={() => onKeyDown({ key })}
          style={{ gridArea }}
          className={classnames(styles.key, keyStatesToStyleMap[keyStates[key]])}
        >
          {label}
        </Button>
      ))}
    </div>
  );
}

export default memo(Keyboard);
