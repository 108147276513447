import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { GameModes } from '../../constants';
import useApplicationState from '../../state/useApplicationState';
import { saveDailyGameState } from '../../utils';
import GameBoard from '../GameBoard';
import Header from '../Header';
import Instructions from '../Instructions';
import Keyboard from '../Keyboard';
import Loader from '../Loader';
import ResultModal from '../ResultModal';
import SplashScreen from '../SplashScreen';
import Toast from '../Toast';
import styles from './App.module.css';

function getGameBoardsStyle(gameMode) {
  if (gameMode === GameModes.DAILY) {
    return { transform: 'translate3d(0,0,0)' };
  }
  if (gameMode === GameModes.UNLIMITED) {
    return { transform: 'translate3d(-50%,0,0)' };
  }
  return {};
}

function renderGameBoard(gameState, scrollContainerRef) {
  return (
    <div ref={scrollContainerRef} className={styles.gameBoardContainer}>
      {gameState.isSolutionLoading ? (
        <div className={styles.loaderContainer}>
          <Loader size={100}/>
        </div>
      ) : (
        <div> {/* Inner container needed so gameboard's min-height works properly the scroll container contents isn't cut off at the top */}
          <GameBoard gameState={gameState}/>
        </div>
      )}
    </div>
  );
}

function App() {
  const [state, actions] = useApplicationState();
  const {
    gameMode,
    gameStates,
    isResultModalOpen,
    isToastOpen,
    toastText,
    areInstructionsOpen,
    isSplashScreenOpen,
  } = state;
  const gameState = gameStates[gameMode];
  const scrollContainerDailyRef = useRef();
  const scrollContainerUnlimitedRef = useRef();
  const toastTimeout = useRef();

  const handleKeyDown = useCallback(({ key }) => {
    actions.keyDown(key);
  }, [actions]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useLayoutEffect(() => {
    const scrollContainerRef = gameMode === GameModes.DAILY ? scrollContainerDailyRef : scrollContainerUnlimitedRef;
    const container = scrollContainerRef.current;
    container.scrollTop = container.scrollHeight - container.clientHeight;
  }, [gameMode, gameState.guesses.length, gameState.currentGuess]);

  useEffect(() => {
    setTimeout(actions.initialize, 0);
  }, [actions.initialize]);

  useEffect(() => {
    if (gameMode === GameModes.DAILY && !gameState.isSolutionLoading) {
      saveDailyGameState(gameState.solution, gameState);
    }
  }, [gameMode, gameState]);

  useEffect(() => {
    if (isToastOpen) {
      clearTimeout(toastTimeout.current);
      toastTimeout.current = setTimeout(actions.hideToast, 2000);
    }
  }, [
    actions.hideToast,
    isToastOpen
  ]);

  return (
    <>
      <div className={styles.app}>
        <div className={styles.headerContainer}>
          <Header
            gameMode={gameMode}
            onGameModeChange={(newGameMode) => actions.setGameMode(newGameMode)}
            onHelpButtonClick={actions.openInstructions}
          />
        </div>
        <div className={styles.gameBoardsContainer} style={getGameBoardsStyle(gameMode)}>
          {renderGameBoard(gameStates[GameModes.DAILY], scrollContainerDailyRef)}
          {renderGameBoard(gameStates[GameModes.UNLIMITED], scrollContainerUnlimitedRef)}
        </div>
        <div className={styles.keyboardContainer}>
          <Keyboard onKeyDown={handleKeyDown} keyStates={gameState.keyStates}/>
        </div>
      </div>
      <ResultModal
        isOpen={isResultModalOpen}
        gameMode={gameMode}
        gameState={gameState}
        onRequestClose={actions.closeResultsModal}
        onRequestNewGame={actions.newGame}
        onShareCopied={(text) => actions.showToast(text)}
      />
      <Instructions isOpen={areInstructionsOpen} onRequestClose={actions.closeInstructions}/>
      <Toast isOpen={isToastOpen} text={toastText}/>
      {!isSplashScreenOpen ? null : <SplashScreen onFinish={actions.closeSplashScreen}/>}
    </>
  );
}

export default App;