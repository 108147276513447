import classnames from 'classnames';
import { HapticFeedbackEvents } from "../../constants";
import { maybeProvideHapticFeedback } from "../../utils";
import styles from './Button.module.css';

function Button({ className, onClick, ...props }) {
  function handleClick(...args) {
    maybeProvideHapticFeedback(HapticFeedbackEvents.BUTTON_PRESS);
    onClick(...args);
  }

  return (
    <button className={classnames(styles.button, className)} onClick={handleClick} {...props}/>
  );
} 

export default Button;
