import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

function withPortal(WrappedComponent) {
  return (props) => {
    const [portalNode, setPortalNode] = useState();

    useEffect(() => {
      const node = document.createElement('div');
      document.body.appendChild(node);
      setPortalNode(node);
      return () => node.remove();
    }, []);
  
    if (portalNode) {
      return createPortal(<WrappedComponent {...props}/>, portalNode);
    }
    return null;
  };
}

export default withPortal;
