const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

// localstorage
const DAILY_STATES_KEY = 'xrdle-daily-game-states';
const HAS_DISMISSED_INSTRUCTIONS_KEY = 'xrdle-has-dismissed-instructions';

// animate.css
const animateCssPrefix = 'animate__';
const AnimateCssClassNames = {
  ANIMATED: `${animateCssPrefix}animated`,
  BACK_OUT_LEFT: `${animateCssPrefix}backOutLeft`,
  BACK_OUT_RIGHT: `${animateCssPrefix}backOutRight`,
  BOUNCE_IN_DOWN: `${animateCssPrefix}bounceInDown`,
  FADE_IN: `${animateCssPrefix}fadeIn`,
  FADE_OUT: `${animateCssPrefix}fadeOut`,
  RUBBER_BAND: `${animateCssPrefix}rubberBand`,
  ZOOM_IN: `${animateCssPrefix}zoomIn`,
};

const TileVerdicts = {
  DEFAULT: 0,
  CORRECT: 1,
  INCORRECT: 2,
  LEFT: 3,
  RIGHT: 4,
  INVALID: 5,
};

const GuessAnnotations = {
  TOO_SHORT: 0,
  TOO_LONG: 1,
};

// Higher indices take precedence
const KeyStates = {
  DEFAULT: 0,
  SHIFT_MORE: 1,
  SHIFT: 2,
  CORRECT_SHIFT_MORE: 3,
  CORRECT_MORE: 4,
  CORRECT_SHIFT: 5,
  CORRECT: 6,
  INCORRECT: 7,
};

const Statuses = {
  ACTIVE: 0,
  VICTORY: 1,
  DEFEAT: 2,
};

const GameModes = {
  DAILY: 0,
  UNLIMITED: 1,
};

const MAX_LENGTH = 20;

const HapticFeedbackEvents = {
  BUTTON_PRESS: 0,
  INVALID_GUESS: 1,
  VICTORY: 2,
};

const hapticFeedbackEventToDurationMap = {
  [HapticFeedbackEvents.INVALID_GUESS]: 150,
  [HapticFeedbackEvents.BUTTON_PRESS]: 5,
  [HapticFeedbackEvents.VICTORY]: 500,
};

export {
  ALPHABET,
  AnimateCssClassNames,
  DAILY_STATES_KEY,
  GameModes,
  GuessAnnotations,
  hapticFeedbackEventToDurationMap,
  HapticFeedbackEvents,
  HAS_DISMISSED_INSTRUCTIONS_KEY,
  KeyStates,
  Statuses,
  TileVerdicts,
  MAX_LENGTH,
};
