import classnames from 'classnames';
import Button from "../Button";
import { ReactComponent as CloseIcon } from './close.svg';
import styles from './CloseButton.module.css';

function CloseButton({ className, onClick }) {
  return (
    <Button
      className={classnames(className, styles.closeButton)}
      onClick={onClick}
    >
      <CloseIcon className={styles.closeIcon}/>
    </Button>
  );
}

export default CloseButton;
